import "./Header.css";
import { ReactComponent as MuskatLogo } from "assets/muskat_logo.svg";
import { isMobile } from "../config";
import React, { useState, useEffect } from "react";

const Header = () => {
  const [isBorderBottom, setIsBorderBottom] = useState(false);
  const borderOnValue = isMobile ? 0 : 50;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition <= borderOnValue) {
        setIsBorderBottom(true);
      } else {
        setIsBorderBottom(false);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [borderOnValue]);

  return (
    <header>
      {!isMobile && (
        <div id="adDiv">
          <div className="topAd">
            <span id="adComment">지금껏 본 적 없던 적립 플랫폼, 머스캣</span>
            <span id="adGoTo">
              가입하고 <span>무료 기프티콘</span> 받기 &gt;
            </span>
          </div>
        </div>
      )}
      <div className={isBorderBottom ? "scrolledStickyDiv" : "stickyDiv"}>
        <div className="topBar">
          <MuskatLogo height="26" />
          <div id="topBarDiv">
            <button
              onClick={() => {
                console.log("로그인");
              }}
              id="signIn"
            >
              로그인
            </button>
            <button
              onClick={() => {
                console.log("회원가입");
              }}
              id="signUp"
            >
              회원가입
            </button>
          </div>
        </div>
      </div>
      <div id="subTopbar">서브탑바</div>
    </header>
  );
};

export default Header;
