import "./MainContainer.css";

const MainContainer = () => {
  return (
    <>
      <div className="MainContainer">여기는 메인 컨테이너</div>
    </>
  );
};

export default MainContainer;
