import DefaultLayout from "layouts/DefaultLayout";
import MainContainer from "../containers/MainContainer";

const MainPage = () => {
  return (
    <DefaultLayout>
      <MainContainer />
    </DefaultLayout>
  );
};

export default MainPage;
